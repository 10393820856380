import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import Container from '../components/Container'
import Layout from '../components/Layout'
import Nav from '../components/nav/Nav'
import Text from '../components/common/Text'

import ContactForm from '../components/ContactForm'

import config from '../utils/siteConfig'
import scrollTo from '../utils/scrollTo'
import { get } from 'lodash'
import { graphql } from 'gatsby'

class IndexPage extends Component {
  componentDidMount() {
    this._checkForHash()
  }

  componentDidUpdate(prevProps) {
    const {
      location: { hash },
    } = this.props
    if (prevProps.location.hash !== hash) {
      this._checkForHash()
    }
  }

  _checkForHash = () => {
    const { location, navigate } = this.props
    const hash = get(location, 'hash')
    if (!!hash) {
      scrollTo(hash.substring(1), false)
      navigate('/', { replace: true })
    }
  }

  render() {
    const { data, siteName } = this.props

    const getSectionData = id => {
      return {
        ...get(data, `allContentful${id}.edges[0].node`),
        siteName,
      }
    }

    const site = getSectionData('Site')
    const navLinks = get(site, 'nav')

    return (
      <Layout>
        <Nav {...site} navLinks={navLinks} />

        <Container>
          <Text.h1 center>Contact</Text.h1>
          <ContactForm />
        </Container>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  siteName: PropTypes.string,
}

IndexPage.defaultProps = {
  siteName: config.siteTitle,
}

export const query = graphql`
  query {
    allContentfulSite {
      edges {
        node {
          siteTitle
          tagLine
          siteDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          keywords
          nav {
            id
            title
            slug
          }
          socialLinks {
            id
            title
            url
            username
            image {
              file {
                url
                details {
                  size
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
